import React, { useState } from "react";
import { TextField } from "@material-ui/core";
import { getTranslation } from "../../heplers/translationHelper";

const FilterApps = ({ onChange }) => {
  const [searchText, setSearchText] = useState("");
  return (
    <TextField
      label={getTranslation("Search...", "Recherche...", "Suche...")}
      variant="outlined"
      fullWidth
      onChange={(e) => setSearchText(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          return onChange(searchText);
        }
      }}
    />
  );
};

export default FilterApps;
