import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Box,
  TextField,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import styles from "../../assests/styles/views/auth/register";
import LoadingButton from "../../components/ButtonWithLoading";
import { Formik } from "formik";
import { FcBusinessman } from "react-icons/fc";
// import checkEmail from "../../helpers/checkEmailFormat";
import { Post, RegisterCustomer, setUser } from "../../actions";
import { useDispatch } from "react-redux";
import checkErrors from "../../utils/responseUtils";
import Alert from "@material-ui/lab/Alert";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import authService from "../../utils/authUtils";
import { getTranslation } from "../../heplers/translationHelper";
import { SET_USER } from "../../actions/types";
import { ImpactSoftLogo, LoginImpactSoftLogo } from "../../icons";
import {
  Post_ExternalAppLogin_URL,
  Post_SendEmail_URL,
} from "../../constants/apiUrls";
import FacebookLogin from "react-facebook-login";
import { v4 as uuid } from "uuid";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { URL } from "../../constants";

const RegisterForm = (props) => {
  const classes = styles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [alert, setAlert] = useState(false);
  let unique_id = localStorage.getItem("visitorId");
  if (unique_id == null) {
    unique_id = uuid();
    localStorage.setItem("visitorId", unique_id);
  }
  if(window.location.search.includes("visitorId")){
    localStorage.setItem("visitorId", window.location.search.split("=")[1]);
    unique_id= window.location.search.split("=")[1];
  }
  const [visitorId, setVisitorId] = useState(unique_id);

  const { enqueueSnackbar } = useSnackbar();

  const redirectURL = authService.getRedirectURL();
  useEffect(() => {
    fetch( URL+"UserActivity/SystemPageLog", {
      method: "POST",
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin",

      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        visitorID: visitorId,
        status: redirectURL == null ? "direct" : redirectURL,
      }),
    });
  },[]);

  useEffect(() => {
    if (redirectURL) {
      var n = 25;
      var number = Math.floor(Math.random() * 9999998) + 1;
      let targetSite = "";
      let address = "";
      if (redirectURL.includes("1/18")) {
        targetSite = "clic-rent.com";
        address = "register_for_free";
      }
      if (targetSite != "") {
        const site = `https://ms112online.influencersoft.com/track/click?url=https%3A%2F%2F${targetSite}%2F${address}&referrer=&host=clic-rent.com&client_id=7VkLnzdUXC71Cjuqu7pYTEyRN0zUt3ghrCmkhD85Q6zgakNdFsD4Ot4QsCbwGoip&source_click_id=${number}`;
        fetch(site)
          .then((resp) => resp.data)
          .then((data) => {
            console.log(data);
          });
      }
    }
  }, []);

  useEffect(() => {
    if (
      window.location.href.includes("impact-soft") &&
      window.location.protocol !== "https:"
    ) {
      window.location.replace(
        `https:${window.location.href.substring(
          window.location.protocol.length
        )}`
      );
    }
  }, []);

  function onExternalLogin(provider, params) {
    console.log("jkashdksajhdashdkjas", params);
    Post(
      {
        provider: provider,
        accessToken: params.accessToken,
      },
      Post_ExternalAppLogin_URL,
      null,
      (res) => {
        enqueueSnackbar("Registration Successfull", {
          variant: "success",
        });
        authService.setSession(res.data.accessToken);
        authService.setUserRefreshToken(
          res.data.refreshToken,
          res.data.refreshTokenExpire
        );
        dispatch({ type: SET_USER, payload: res.data.user });
        if (redirectURL) {
          authService.removeRedirectURL();

          history.push(redirectURL);
        } else {
          history.push("/myapps");
        }
      },
      (error) => {}
    );
  }

  const [errorMessage,setErrorMessage]=useState()

  return (
    <>
      <Container maxWidth="sm">
        <Paper elevation={3} className={classes.root}>
          <div className={classes.paper}>
            <ImpactSoftLogo />
						<br />
						<br />
            <Typography className={classes.form} component="h1" variant="h4">
              {getTranslation("Sign up", "S'inscrire", "Anmelden")}
            </Typography>
            {alert ? (
              <Box my={1} width="100%">
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {getTranslation(
                    "Please Check your Email or Password",
                    "Please Check your Email or Password",
                    "Please Check your Email or Password"
                  )}
                </Alert>
              </Box>
            ) : null}
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                // userName: "",
                password: "",
                confirmPassword: "",
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().required("Required"),
                lastName: Yup.string().required("Required"),
                email: Yup.string().email().required("Required"),
                password: Yup.string()
                  .min(6, "Password atleast 6 character long")
                  .required("Password is required"),
                confirmPassword: Yup.string()
                  .min(6, "Password atleast 6 character long")
                  .oneOf([Yup.ref("password"), null], "Passwords must match"),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                dispatch(RegisterCustomer(values, history)).then((res) => {
                  const errors = checkErrors(res);
                  if (!errors) {
                    enqueueSnackbar("Registration Successfull", {
                      variant: "success",
                    });
                    authService.setSession(res.data.accessToken);
                    authService.setUserRefreshToken(
                      res.data.refreshToken,
                      res.data.refreshTokenExpire
                    );
                    dispatch({ type: SET_USER, payload: res.data.user });
                    if (redirectURL) {
                      authService.removeRedirectURL();

                      history.push(redirectURL);
                    } else {
                      history.push("/myapps");
                    }
                    setSubmitting(false);
                    Post(
                      {
                        "fromName": "App Manager",
                        "to": [
                          {
                            "name": "App Manager",
                            "address": "ms112online@gmail.com"
                          }
                        ],
                        "subject": "App Manager New Visitor Registration Success",
                        "message": "<p>App Manager had detected new visitor from</p>"+"<p>Visitor Id: "+visitorId+"</br></br><pre>User model: "+JSON.stringify(values)+"</pre>"+"</br></br><p>Error Details</p><pre>"+JSON.stringify(errors)+"</pre>",
                        "isHtml": true,
                      },
                      Post_SendEmail_URL,
                      null,
                      resp=>{},
                      error=>{
                
                      }
                      )
                  } else {
                    Post(
                      {
                        "fromName": "App Manager",
                        "to": [
                          {
                            "name": "App Manager",
                            "address": "info@impact-consult.net"
                          }
                        ],
                        "subject": "App Manager New Visitor Registration Failed",
                        "message": "<p>App Manager had detected new visitor registration failed</p>"+"<p>Visitor Id: "+visitorId+"</br></br><pre>User model: "+JSON.stringify(values)+"</pre>"+"</br></br><p>Error Details</p><pre>"+JSON.stringify(errors)+"</pre>",
                        "isHtml": true,
                      },
                      Post_SendEmail_URL,
                      null,
                      resp=>{},
                      error=>{
                
                      }
                      )
                      
                    if (res.data?.includes("User already exists")) {
                      history.push(
                        "/login?error=already_have"
                      );
                      enqueueSnackbar(
                        getTranslation(
                          "You have already an account. Please login here"
                        ),
                        { variant: "error" }
                      );
                    }
                    // errors?.map((error) =>
                    //   enqueueSnackbar(error.message, { variant: "error" })
                    // );
                    setSubmitting(false);
                  }
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item sm={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="firstName"
                        label={getTranslation(
                          "First Name",
                          "Prénom",
                          "Vorname"
                        )}
                        value={values.firstName}
                        onChange={handleChange}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        name="lastName"
                        label={getTranslation("Last Name", "Nom", "Nachname")}
                        value={values.lastName}
                        onChange={handleChange}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                      />
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label={getTranslation("Email", "Email", "Email")}
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="password"
                        label={getTranslation(
                          "Password",
                          "Mot de passe",
                          "Passwort"
                        )}
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        error={touched.password && Boolean(errors.password)}
                        helperText={touched.password && errors.password}
                      />
                    </Grid>
                    <Grid item sm={12} md={6} lg={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="password"
                        label={getTranslation(
                          "Confirm Password",
                          "Confirmer le mot de passe",
                          "Passwort bestätigen"
                        )}
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        error={
                          touched.confirmPassword &&
                          Boolean(errors.confirmPassword)
                        }
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                      />
                    </Grid>
                    <Grid item sm={12} md={12} lg={12}>
                      <LoadingButton
                        color="primary"
                        label={getTranslation(
                          "Register",
                          "S'inscrire",
                          "Registrieren"
                        )}
                        loading={isSubmitting}
                        type="submit"
                        variant="contained"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
            <Box
              mt={2}
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Link to="/login" variant="h6" className={classes.link}>
                <Typography>
                  {getTranslation(
                    "Already have a account? Sign in",
                    "Already have a account? Sign in",
                    "Already have a account? Sign in"
                  )}
                </Typography>
              </Link>
            </Box>
          </div>
        </Paper>
      </Container>
      <script
        type="text/javascript"
        async=""
        src="https://ms112online.influencersoft.com/click/?host=clic-rent.com&amp;client_id=7VkLnzdUXC71Cjuqu7pYTEyRN0zUt3ghrCmkhD85Q6zgakNdFsD4Ot4QsCbwGoip&amp;httpReferer=https%3A%2F%2Fclic-rent.com%2FABC1234"
      ></script>
    </>
  );
};

export default RegisterForm;
