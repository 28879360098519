import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Get, Post } from "../../actions";
import Table from "../../components/table";
import {
  Get_LanguagesWithPagination_URL,
  Post_AddNewText_URL,
  Post_UpdateTextTranslation_URL,
} from "../../constants/apiUrls";
import { getTranslation } from "../../heplers/translationHelper";
import { AddNewTextDialog } from "./components/addTextDialog";

const columns = [
  {
    id: "text",
    label: getTranslation("Text", "Texte", "Text"),
  },
  {
    id: "english",
    label: getTranslation("English", "Anglais", "English"),
  },
  {
    id: "french",
    label: getTranslation("French", "Fran�ais", "French"),
  },
  {
    id: "german",
    label: getTranslation("German", "Allemand", "German"),
  },
  {
    id: "dutch",
    label: getTranslation("Dutch", "Dutch", "Dutch"),
  },
];
export const TranslationsList = () => {
  /////// Table data handling states
  const [editTranslation, setEditTranslation] = useState(null);
  const [translationsList, setTranslationsList] = useState([]);
  const [translationsListLoading, setTranslationsListLoading] = useState([]);
  const [openAddNewTextDialog, setOpenAddNewTextDialog] = useState(false);
  ///// handlerd state//////
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchString, setSearchString] = useState("");
  const [sort, setSort] = useState("");
  const [sortDirection, setSortDirection] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  ////////// Handlers

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setPage(0);
  };
  /////////  Load Translations ///////////////
  useEffect(() => {
    loadTranslationsList({
      searchString: searchString,
      pageNumber: page,
      pageSize: rowsPerPage,
      sort: sort,
      sortDirection: sortDirection,
    });
  }, [searchString, page, rowsPerPage, sort, sortDirection]);
  const loadTranslationsList = () => {
    setTranslationsListLoading(true);
    Get(
      {
        searchString: searchString,
        pageNumber: page,
        pageSize: rowsPerPage,
        sort: sort,
        sortDirection: sortDirection,
      },
      Get_LanguagesWithPagination_URL,
      null,
      (resp) => {
        setTranslationsListLoading(false);
        setTranslationsList(resp.data);
      },
      (error) => {
        enqueueSnackbar("Translations\n" + error.data, { variant: "error" });
        setTranslationsListLoading(false);
      }
    );
  };
  const handleEditTranslation = (row) => {
    setEditTranslation(row);
    setOpenAddNewTextDialog(true);
  };

  /////// Hanlde Text Submission
  const handleSubmitText = (values, actions) => {
    Post(
      values,
      editTranslation ? Post_UpdateTextTranslation_URL : Post_AddNewText_URL,
      null,
      (resp) => {
        actions.setSubmitting(false);
        editTranslation
          ? enqueueSnackbar("Word Translation Updated", {
              variant: "success",
            })
          : enqueueSnackbar("Word Translation Added", {
              variant: "success",
            });
        setEditTranslation(null);
        loadTranslationsList();
        setOpenAddNewTextDialog(false);
        console.log("kjgdfhgsdfgds",resp.data)
      },
      (error) => {
        setOpenAddNewTextDialog(false);
        enqueueSnackbar("Error Translation Submission\n" + error.data, {
          variant: "error",
        });
      }
    );
  };
  return (
    <>
      <Table
        dense
        title={getTranslation("Translations")}
        colums={columns}
        rows={translationsList?.data}
        count={translationsList?.totalCount}
        page={page}
        rowsPerPage={rowsPerPage}
        handleChangePage={(event, newPage) => handleChangePage(event, newPage)}
        handleChangeRowsPerPage={(e) => handleChangeRowsPerPage(e)}
        viewSearch
        onSearch={(search) => setSearchString(search)}
        viewEdit
        onEdit={(row) => handleEditTranslation(row)}
        viewCreate
        onCreate={() => setOpenAddNewTextDialog(true)}
      />
      <AddNewTextDialog
        isOpen={openAddNewTextDialog}
        onClose={() => setOpenAddNewTextDialog(false)}
        editTranslation={editTranslation}
        onSubmit={handleSubmitText}
      />
    </>
  );
};
