import React, { useEffect, useState } from "react";
import styles from "../assests/styles/layout/Layout";
import TopBar from "./topbar";
import SidrBar from "./sidebar";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import EmailOutlined from "@material-ui/icons/EmailOutlined";
import { useSelector } from "react-redux";
import { getLanguage, Post } from "../actions";
import { useSnackbar } from "notistack";
import { Post_ResendConfirmationEmail_URL } from "../constants/apiUrls";
import { getTranslation } from "../heplers/translationHelper";

const DashboardLayout = (props) => {
	const classes = styles();
	const { enqueueSnackbar } = useSnackbar();
	const [isMobileNavOpen, setMobileNavOpen] = useState(false);
	const { user } = useSelector((state) => state.auth);
	const [isResendingConfrimationMail, setIsResendingConfrimationMail] =
		useState(false);
	const resendConfirmationEmail = (e) => {
		e.preventDefault();
		setIsResendingConfrimationMail(true);
		Post(
			{},
			Post_ResendConfirmationEmail_URL,
			null,
			(resp) => {
				setIsResendingConfrimationMail(false);
				enqueueSnackbar(
					getTranslation(
						"Confirmation mail has been sent",
						"Confirmation mail has been sent",
						"Confirmation mail has been sent"
					),
					{
						variant: "success",
					}
				);
			},
			(error) => {
				setIsResendingConfrimationMail(false);
				enqueueSnackbar(
					getTranslation(
						"Something went wrong. Please contact with admin.",
						"Something went wrong. Please contact with admin.",
						"Something went wrong. Please contact with admin."
					),
					{
						variant: "error",
					}
				);
			}
		);
	};

	const [loadingTranslation, setLoadingTranslation] = useState(true);

	useEffect(() => {
		getUpdatedTranslation();
	}, []);
	const getUpdatedTranslation = () => {
		if (localStorage.getItem("lang") === null) {
			localStorage.setItem("lang", "fr");
		}
		getLanguage(
			(resp) => {
				localStorage.setItem("dictionary", JSON.stringify(resp.data));
				setLoadingTranslation(false);
			},
			(error) => {
				setLoadingTranslation(false);
			}
		);
	};

	return loadingTranslation ? (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="center"
			flexDirection="column"
		>
			<CircularProgress style={{ margin: "auto" }} />
		</Box>
	) : (
		<div className={classes.root}>
			<TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
			<SidrBar
				onMobileClose={() => setMobileNavOpen(false)}
				openMobile={isMobileNavOpen}
			/>

			<div className={classes.wrapper}>
				<div className={classes.contentContainer}>
					<div className={classes.content}>
						{user && !user?.emailConfirmed ? (
							<Box mb={2} className={classes.notificationBar}>
								{isResendingConfrimationMail ? (
									<CircularProgress style={{ margin: "auto" }} />
								) : (
									<Typography style={{ color: "white" }}>
										{getTranslation(
											"A confirmation mail has been sent to",
											"Un mail de confirmation a été envoyé à",
											"Eine Bestätigungsmail wurde an gesendet"
										) + " "}
										{user.email}.
										{" " +
											getTranslation(
												"Please check your inbox/spam to verify your email or",
												"Veuillez vérifier votre boîte de réception/spam pour vérifier votre adresse e-mail ou",
												"Bitte überprüfen Sie Ihren Posteingang/Spam, um Ihre E-Mail zu bestätigen, oder"
											) +
											" "}
										<a
											href="#"
											style={{ color: "white" }}
											onClick={resendConfirmationEmail}
										>
											{getTranslation(
												"Click here to resend",
												"Cliquez ici pour renvoyer ",
												"Klicken Sie hier, um erneut zu senden"
											)}
										</a>
									</Typography>
								)}
							</Box>
						) : null}
						{props.children}
					</div>
				</div>
			</div>
		</div>
	);
};
export default DashboardLayout;
